body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.box {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 3px rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.1);
  display: block;
  padding: 1rem;
}
